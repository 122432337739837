import React from 'react'
import '../GraphBackground/GraphBg.css';

function GraphBackground() {
  return (
    <div className='bg2'></div>
  )
}

export default GraphBackground
