import { useEffect } from "react";
import gsap from "gsap";
import "./SecAnimation.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Observer);

function SecAnimation() {
  useEffect(() => {
    const animatedElements = document.querySelectorAll(".triggeredAnim");
    animatedElements.forEach((element) => {
      if (window.innerWidth >= 992) {
        gsap.fromTo(
          element,
          { y: 90, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            duration: 0.8,
            ease: "power2.inOut",
            scrollTrigger: {
              trigger: element,
              start: "top 80%",
              toggleActions: "play none none none",
            },
          }
        );
      } else {
        gsap.to(element, { y: 0, opacity: 1 });
      }
    });

    // Cleanup function to remove ScrollTrigger instances
    return () => ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  }, []);

  useEffect(() => {
    const sections = document.querySelectorAll(".animsec");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const animatedElements =
              entry.target.querySelectorAll(".animatedcont");
            if (window.innerWidth >= 992) {
              gsap.fromTo(
                animatedElements,
                { y: 90, opacity: 0 },
                { y: 0, opacity: 1, duration: 0.8, stagger: 0.4 }
              );
            } else {
              gsap.to(animatedElements, { opacity: 1 });
            }
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  return null;
}

export default SecAnimation;
