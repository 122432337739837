import React from "react";
import { useLocation } from "react-router-dom";
// Styles sheet
import "./Footer.css";

// Assets
import Logo from "../../assets/img/Logo.svg";
import Behance from "../../assets/img/Behance.svg";
import Linkedin from "../../assets/img/Linkedin.svg";
import Twitter from "../../assets/img/Twitter.svg";
import GraphBg2 from "../../Components/GraphBackground/GraphBg2";

function Footer() {
  const location = useLocation();

  // Define paths where the footer should be hidden
  const hiddenFooterPaths = ["/Netto", "/HealthHub"];

  // Determine if the footer should be hidden
  const shouldHideFooter = hiddenFooterPaths.includes(location.pathname);

  // Render nothing if the footer should be hidden
  if (shouldHideFooter) return null;

  return (
    <>
      <footer>
        <section className="sections">
          <div className="containers ls">
            <div className="content">
              <div className="logo-content">
                <img src={Logo} alt="Logo" />
                <p className="t-lg-regular">
                  Crafting digital experiences, one pixel at a time.
                </p>
              </div>
              <div className="social-content">
                <a href="https://www.behance.net/jayvincismael">
                  <img src={Behance} alt="Behance" />
                </a>
                <a href="https://www.linkedin.com/in/jayvin-carl-m-632a8b179/">
                  <img src={Linkedin} alt="Linkedin" />
                </a>
                <a href="https://twitter.com/Night85438467">
                  <img src={Twitter} alt="Twitter" />
                </a>
              </div>
            </div>
            <div className="content">
              <p className="t-md-regular">
                Designed with care ❤️ by me, and expertly coded by my brother.
              </p>
            </div>
          </div>
        </section>
      </footer>
      <GraphBg2 />
    </>
  );
}

export default Footer;
