import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

// Styles sheet
import "./Password.css";

// Assets
import Eye from "../../assets/img/Eye.svg";
import EyeOff from "../../assets/img/EyeOff.svg";
import Close from "../../assets/img/Close.svg";

const Password = ({ onSuccess }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [isPassValid, setPassValid] = useState(null);

  // Toggle password visibility
  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisible(!passwordVisible);
  }, [passwordVisible]);

  // Handle password input change
  const handlePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  // Validate password and scroll to top on success
  const handleValidation = useCallback(
    (e) => {
      if (e.key === "Enter") {
        if (password === "Passw0rd/123") {
          setPassValid(true);
          onSuccess();
        } else {
          setPassValid(false);
        }
        scroll.scrollToTop({ behavior: "auto", passive: true });
        setPassword("");
      }
    },
    [password, onSuccess]
  );

  return (
    <>
      <div className="pass-container">
        <Link
          to="/"
          onClick={() => {
            scroll.scrollToTop({ behavior: "smooth", passive: true });
          }}
        >
          <div className="close-pass-container">
            <img className="close-pass-btn" src={Close} alt="Close" />
          </div>
        </Link>

        <div className="pass-inner-container">
          <div className="pass-content">
            <h3 className="d-md-bold">Password protected</h3>
            <p className="t-xl-regular">
              Enter the password to access this project
            </p>
            <div className="pass-input-wrapper">
              <div className="input-and-toggle">
                <input
                  type={passwordVisible ? "text" : "password"}
                  value={password}
                  placeholder="Password"
                  onChange={handlePassword}
                  onKeyDown={handleValidation}
                  className={`pass-input ${
                    isPassValid === false ? "invalid" : ""
                  }`}
                />
                <button
                  className="toggle-button"
                  onClick={togglePasswordVisibility}
                >
                  <img
                    className="eye-icon"
                    src={passwordVisible ? EyeOff : Eye}
                    alt={passwordVisible ? "Hide password" : "Show password"}
                  />
                </button>
              </div>
              {isPassValid !== null && (
                <p
                  className={`t-sm-regular-err ${
                    isPassValid === false ? "error" : ""
                  }`}
                >
                  {!isPassValid ? "Incorrect password. Please try again" : ""}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Password;
