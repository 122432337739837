import React from "react";
import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";

// Components
import GraphBg1 from "../../Components/GraphBackground/GraphBg1";

// Styles sheet
import "./Enp.css";

// Assets
import ProgreIllu from "../../assets/img/SCInProgress.svg";

function PageProgression() {
  return (
    <>
      <div className="enp">
        <GraphBg1 />
        <div className="content">
          <img src={ProgreIllu} alt="illustration" loading="lazy" />
          <h4 className="d-md-bold de-d-lg-bold">Case Study in Progress</h4>
          <p className="t-xl-regular">
            Hey! I'm currently working on this exciting project and can't wait
            to share more soon. Stay tuned!
          </p>
          <Link to="/" onClick={() => scroll.scrollToTop(0)}>
            <p className="t-xl-medium">Back home</p>
          </Link>
        </div>
      </div>
    </>
  );
}

export default PageProgression;
