import React, { useCallback, useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";

// Styles sheet
import "./CHT.css";

// Compnenents
import LightBox from "../../Components/LightBox/LightBox";
import GraphBg1 from "../../Components/GraphBackground/GraphBg1";
import SecAnimation from "../../Components/SectionAnimation/SecAnimation";

// Assets
import Cliqqimg1 from "../../assets/img/Cliqqimg1.png";
import Cliqqimg2 from "../../assets/img/Cliqqimg2.png";
import Cliqqimg3 from "../../assets/img/Cliqqimg3.png";
import Cliqqimg4 from "../../assets/img/Cliqqimg4.png";
import Cliqqimg5 from "../../assets/img/Cliqqimg5.png";
import Cliqqimg6 from "../../assets/img/Cliqqimg6.png";
import Cliqqimg7 from "../../assets/img/Cliqqimg7.png";
import Cliqqimg8 from "../../assets/img/Cliqqimg8.png";
import Icon1 from "../../assets/img/Icon1.png";
import Icon2 from "../../assets/img/Icon2.png";
import Icon3 from "../../assets/img/Icon3.png";
import Icon4 from "../../assets/img/Icon4.png";
import Iphone1 from "../../assets/img/Iphone1.png";
import Iphone2 from "../../assets/img/Iphone2.png";
import Iphone3 from "../../assets/img/Iphone3.png";
import Screen1 from "../../assets/img/Screen1.png";
import Screen2 from "../../assets/img/Screen2.png";
import Screen3 from "../../assets/img/Screen3.png";
import Screen4 from "../../assets/img/Screen4.png";
import Screen5 from "../../assets/img/Screen5.png";
import Screen6 from "../../assets/img/Screen6.png";
import Screen7 from "../../assets/img/Screen7.png";
import Screen8 from "../../assets/img/Screen8.png";
import Screen9 from "../../assets/img/Screen9.png";
import Screen10 from "../../assets/img/Screen10.png";
import Screen11 from "../../assets/img/Screen11.png";
import Screen12 from "../../assets/img/Screen12.png";
import Screen13 from "../../assets/img/Screen13.png";
import Screen14 from "../../assets/img/Screen14.png";
import Screen15 from "../../assets/img/Screen15.png";
import ScrollArrow from "../../assets/img/ScrollArrow.svg";
import transition2 from "../../Components/PageTransition2/Transition2";

function CliQQ() {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState("");
  const [isHideBtn, setHideBtn] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);

  // Function for if user click the image to open.
  const handleLightboxOpen = useCallback((image) => {
    setIsLightboxOpen(true);
    setLightboxImage(image);
  }, []);

  // Function for if user click the x or close icon to close.
  const handleCloseLightbox = useCallback(() => {
    setIsLightboxOpen(false);
    setLightboxImage("");
  }, []);

  // Function for scroll to top button.
  // If the scrollY is greater than the current then hide the button up,
  // Else then show the button up.
  const handleScroll = useCallback(() => {
    if (window.scrollY > lastScrollY) {
      setHideBtn(false);
    } else {
      setHideBtn(true);
    }
    setLastScrollY(window.scrollY);
  }, [lastScrollY]);

  // To avoid recreating element and to ensure it.
  useEffect(() => {
    const handleAutoHide = () => setHideBtn(true);

    let timeoutId = setTimeout(handleAutoHide, 2000);

    const onScroll = () => {
      clearTimeout(timeoutId);
      handleScroll();
      timeoutId = setTimeout(handleAutoHide, 2000);
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("scroll", onScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <SecAnimation />
      <GraphBg1 />
      {/* <main> */}
        {/* Hero section */}
        <section className="sections animsec">
          <div className="sc-outer-container flex">
            <div className="cliqqCover cover"></div>
            <div className="sc-inner-container">
              <div className="content p-block-1 animatedcont">
                <h5 className="d-sm-bold mb-0_25">CLiQQ Mobile App Redesign</h5>
                <div className="chip">
                  <p className="t-sm-medium">Mobile app</p>
                </div>
                <p className="t-md-medium de-t-xl-regular">
                  CliQQ is a mobile app designed for 7-Eleven shoppers. It
                  offers a digital loyalty program, convenient payment options,
                  and exclusive deals. Earn points, enjoy rewards, and make your
                  7-Eleven visits more rewarding with CliQQ.
                </p>
              </div>
              <div className="content p-block-1">
                <div className="row">
                  <div className="col animatedcont">
                    <p className="t-sm-regular">Role</p>
                    <p className="t-md-medium de-t-xl-medium">UI/UX Designer</p>
                  </div>
                  <div className="col animatedcont">
                    <p className="t-sm-regular">Duration</p>
                    <p className="t-md-medium de-t-xl-medium">3 Months</p>
                  </div>
                  <div className="col animatedcont">
                    <p className="t-sm-regular">Tools</p>
                    <p className="t-md-medium de-t-xl-medium">
                      Figma, Adobe Fresco, Notion, Adobe Illustrator, Procreate
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Problem section */}
        <section className="sections animsec">
          <div className="sc-containers flex-2 row-rev animatedcont">
            <div className="content">
              <h5 className="d-sm-bold">Problem</h5>
              <p className="t-md-regular de-t-xl-regular">
                The current CliQQ mobile app lacks intuitive navigation,
                hindering user engagement and satisfaction. A redesign is
                necessary to improve user experience, streamline flows, and
                promote active engagement through a visually appealing
                interface.
              </p>
            </div>
            <div className="content">
              <img
                src={Cliqqimg1}
                alt="Illustration"
                className="sc-img"
                loading="lazy"
              />
            </div>
          </div>
        </section>

        {/* Old design section */}
        <section className="sections animsec">
          <div className="sc-containers flex">
            <div className="content animatedcont">
              <h5 className="d-sm-bold">Old design</h5>
              <p className="t-md-regular de-t-xl-regular">
                Here is the original screens of the CliQQ mobile app before my
                redesign and highlight some of the key usability issues.
                <br />
                <br />
                Home Screen: Cluttered layout with unclear navigation. Confusing
                Navigation: Navigation icons and labels were unclear and
                unintuitive, making it difficult for users to find and access
                desired features efficiently.
                <br />
                <br />
                By examining these old screens, we can identify specific design
                elements and usability challenges that drove my decision to
                redesign the CliQQ app.
              </p>
            </div>
            <div className="content animatedcont">
              <img
                src={Cliqqimg2}
                alt="Screen"
                className="sc-img lb-img"
                loading="lazy"
                onClick={() => handleLightboxOpen(Cliqqimg2)}
              />
            </div>
          </div>
        </section>

        {/* Solution section */}
        <section className="sections animsec">
          <div className="sc-containers flex-2 animatedcont">
            <div className="content">
              <h5 className="d-sm-bold">Solution</h5>
              <p className="t-md-regular de-t-xl-regular">
                The CliQQ mobile app redesign I incorporated character-based
                design, playful icon illustrations, gamification elements,
                streamlined navigation, and enhanced visual design. These
                changes made the app more engaging, friendly, and visually
                appealing, fostering active participation and a sense of
                enjoyment and reward for users.
              </p>
            </div>
            <div className="content">
              <img
                src={Cliqqimg3}
                alt="Illustration"
                className="sc-img"
                loading="lazy"
              />
            </div>
          </div>
        </section>

        {/* Research section */}
        <section className="sections animsec">
          <div className="sc-containers flex-2 row-rev animatedcont">
            <div className="content">
              <h5 className="d-sm-bold">Research</h5>
              <p className="t-md-regular de-t-xl-regular">
                In redesigning the CliQQ mobile app, I analyzed app store
                feedback and conducted informal usability tests with friends to
                identify user needs and pain points. This guided the creation of
                a more user-centered design, with insights shaping key decisions
                to enhance user experience and effectiveness.
              </p>
            </div>
            <div className="content">
              <img
                src={Cliqqimg4}
                alt="Illustration"
                className="sc-img"
                loading="lazy"
              />
            </div>
          </div>
        </section>

        {/* Findings section */}
        <section className="sections animsec">
          <div className="sc-containers flex">
            <div className="content animatedcont">
              <h5 className="d-sm-bold">Findings</h5>
              <p className="t-md-regular de-t-xl-regular">
                My user research provided several key insights
              </p>
            </div>
            <div className="content">
              <div className="row1">
                <div className="col1 animatedcont">
                  <p className="t-lg-bold de-t-xl-bold">
                    Navigation challenges
                  </p>
                  <p className="t-md-regular de-t-lg-regular">
                    Users expressed difficulties in finding and accessing
                    specific features within the app. Some reported confusion in
                    navigating through different sections animsec, leading to
                    frustration.
                  </p>
                </div>
                <div className="col1 animatedcont">
                  <p className="t-lg-bold de-t-xl-bold">
                    Loyalty program understanding
                  </p>
                  <p className="t-md-regular de-t-lg-regular">
                    Many users struggled to grasp the details of the loyalty
                    program, such as earning points and redeeming rewards. This
                    lack of clarity hindered their ability to fully benefit from
                    the program.
                  </p>
                </div>
                <div className="col1 animatedcont">
                  <p className="t-lg-bold de-t-xl-bold">Deals utilization</p>
                  <p className="t-md-regular de-t-lg-regular">
                    Users faced obstacles in effectively taking advantage of
                    deals offered within the app. Some mentioned difficulties in
                    discovering these deals or redeeming them at the checkout.
                  </p>
                </div>
                <div className="col1 animatedcont">
                  <p className="t-lg-bold de-t-xl-bold">Aesthetic design</p>
                  <p className="t-md-regular de-t-lg-regular">
                    Users voiced dissatisfaction with the aesthetic design of
                    the current CliQQ mobile app, describing it as outdated and
                    lacking visual appeal, which detracted from their overall
                    experience and perception of the app.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Iconography section */}
        <section className="sections animsec">
          <div className="sc-containers flex animatedcont">
            <div className="content">
              <h5 className="d-sm-bold">Iconography</h5>
              <p className="t-md-regular de-t-xl-regular">
                In the Cliqq mobile app redesign, I conducted a significant
                overhaul of the iconography, prioritizing usability and clarity
                to ensure intuitive and recognizable user interactions. Thorough
                evaluation and redesign of existing icons aligned them with the
                updated visual style, emphasizing simplicity and visual
                hierarchy. The refined iconography enhanced user understanding
                and navigation, ultimately improving overall usability and
                creating a delightful user experience within the Cliqq mobile
                app.
              </p>
            </div>
            <div className="content">
              <img
                src={Cliqqimg5}
                alt="Illustration"
                className="sc-img lb-img"
                loading="lazy"
                onClick={() => handleLightboxOpen(Cliqqimg5)}
              />
            </div>
          </div>
        </section>

        {/* Typography section */}
        <section className="sections animsec">
          <div className="sc-containers flex">
            <div className="content animatedcont">
              <h5 className="d-sm-bold">Typography</h5>
              <p className="t-md-regular de-t-xl-regular">
                When working on the CliQQ mobile app redesign, the choice of
                typography held great significance in defining the app's visual
                identity and overall user experience. After thorough evaluation
                and consideration, I carefully selected "Nunito" as the primary
                typeface for the following key reasons:
              </p>
            </div>
            <div className="content animatedcont">
              <img
                src={Cliqqimg6}
                alt="Illustration"
                className="sc-img"
                loading="lazy"
              />
            </div>
            <div className="content">
              <div className="row1">
                <div className="col1 animatedcont flex-row">
                  <div>
                    <img
                      src={Icon1}
                      alt="Icon"
                      className="sc-img icon-img"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <p className="t-lg-bold de-t-xl-bold">
                      Readability and legibility
                    </p>
                    <p className="t-md-regular de-t-lg-regular">
                      Nunito's excellent legibility and clean design were
                      paramount in creating a seamless reading experience for
                      users. It was crucial for the app's content to be easily
                      understood, ensuring smooth interactions throughout the
                      app.
                    </p>
                  </div>
                </div>
                <div className="col1 animatedcont flex-row">
                  <div>
                    <img
                      src={Icon2}
                      alt="Icon"
                      className="sc-img icon-img"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <p className="t-lg-bold de-t-xl-bold">Modern aesthetics</p>
                    <p className="t-md-regular de-t-lg-regular">
                      The contemporary and elegant look of Nunito perfectly
                      aligned with my vision for the CliQQ app's modern and
                      visually appealing interface. The rounded letterforms and
                      harmonious proportions added a touch of sophistication,
                      elevating the overall design.
                    </p>
                  </div>
                </div>
                <div className="col1 animatedcont flex-row">
                  <div>
                    <img
                      src={Icon3}
                      alt="Icon"
                      className="sc-img icon-img"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <p className="t-lg-bold de-t-xl-bold">Versatility</p>
                    <p className="t-md-regular de-t-lg-regular">
                      Nunito's varied weights and styles provided me with the
                      flexibility needed to establish clear hierarchies for
                      different text elements. This versatility allowed me to
                      distinguish headings, subheadings, and body text
                      effectively, ensuring a well-organized content structure.
                    </p>
                  </div>
                </div>
                <div className="col1 animatedcont flex-row">
                  <div>
                    <img
                      src={Icon4}
                      alt="Icon"
                      className="sc-img icon-img"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <p className="t-lg-bold de-t-xl-bold">User familiarity</p>
                    <p className="t-md-regular de-t-lg-regular">
                      Nunito's popularity in various digital interfaces,
                      including apps and websites, made it an excellent choice.
                      Utilizing a familiar typeface ensured a sense of comfort
                      and ease for users, enhancing their overall navigation
                      experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Characters section */}
        <section className="sections animsec">
          <div className="sc-containers flex">
            <div className="content animatedcont">
              <h5 className="d-sm-bold">Characters</h5>
              <p className="t-md-regular de-t-xl-regular">
                As the sole UI/UX designer for the Cliqq mobile app redesign, I
                crafted unique characters to maintain brand consistency, serving
                as brand ambassadors to enhance engagement, foster trust, and
                reinforce the brand identity throughout the app.
              </p>
            </div>
            <div className="content animatedcont">
              <img
                src={Cliqqimg7}
                alt="Illustration"
                className="sc-img"
                loading="lazy"
              />
            </div>
            <p className="t-md-regular de-t-xl-regular animatedcont">
              Joe and Lala are the energetic and positive characters for the 7
              Eleven Cliqq mobile application. Joe, always in a vibrant red
              t-shirt, has contagious enthusiasm and a can-do attitude, making
              the Cliqq journey fun and rewarding. Lala, wearing an orange
              t-shirt, radiates positivity with her bright smile and cheerful
              demeanor. Together, they guide users through an exciting adventure
              of convenience and rewards, ensuring a delightful and memorable
              experience with 7 Eleven Cliqq.
            </p>
          </div>
        </section>

        {/* Redesigned screen section */}
        <section className="sections animsec">
          <div className="sc-containers flex-2 animatedcont">
            <div className="content">
              <h5 className="d-sm-bold t-center">Redesigned screens</h5>
              <p className="t-md-regular de-t-xl-regular t-center">
                With a fresh look and easier navigation, every screen is
                redesigned for a better experience.
              </p>
            </div>
          </div>
        </section>

        {/* A Personalized Shopping Experience section */}
        <section className="sections animsec">
          <div className="sc-containers flex-2 animatedcont">
            <div className="content">
              <h5 className="d-sm-bold">A Personalized Shopping Experience</h5>
              <p className="t-md-regular de-t-xl-regular">
                Key functions like bill payments, buying load, and adding
                e-money are easily accessible in a grid layout. The intuitive
                design allows seamless navigation for features such as
                requesting money, loading wallets, and accessing Wi-Fi,
                enhancing user interaction and simplifying transactions.
              </p>
            </div>
            <div className="content">
              <img
                src={Iphone1}
                alt="Iphone"
                className="sc-img lb-img"
                loading="lazy"
                onClick={() => handleLightboxOpen(Iphone1)}
              />
            </div>
          </div>
        </section>

        {/* Rewards and Gamification section */}
        <section className="sections animsec">
          <div className="sc-containers flex-2 row-rev animatedcont">
            <div className="content">
              <h5 className="d-sm-bold">Rewards and Gamification</h5>
              <p className="t-md-regular de-t-xl-regular">
                I introduced CliQQ Points with a gamified experience, featuring
                tiers like Bronze, Silver, Gold, and Platinum. As you earn
                points, you unlock exclusive rewards and benefits, supported by
                a visually refined interface for a modern and rewarding journey.
              </p>
            </div>
            <div className="content">
              <img
                src={Iphone2}
                alt="Iphone"
                className="sc-img lb-img"
                loading="lazy"
                onClick={() => handleLightboxOpen(Iphone2)}
              />
            </div>
          </div>
        </section>

        {/* Digital convenience section */}
        <section className="sections animsec">
          <div className="sc-containers flex-2 animatedcont">
            <div className="content">
              <h5 className="d-sm-bold">Digital convenience</h5>
              <p className="t-md-regular de-t-xl-regular">
                I streamlined the Reward Cards feature, enabling real-time
                points tracking and seamless redemption. Users can now link
                their physical 7-Eleven reward card to the CliQQ app, gaining
                instant access to their points balance for easier and more
                rewarding shopping experiences.
              </p>
            </div>
            <div className="content">
              <img
                src={Iphone3}
                alt="Iphone"
                className="sc-img lb-img"
                loading="lazy"
                onClick={() => handleLightboxOpen(Iphone3)}
              />
            </div>
          </div>
        </section>

        {/* The screens section */}
        <section className="sections animsec">
          <div className="sc-containers flex-2">
            <div className="content animatedcont">
              <h5 className="d-sm-bold t-center">The screens</h5>
              <p className="t-md-regular de-t-xl-regular t-center">
                Each screen aims to provide a seamless and engaging user
                journey, ensuring a more intuitive and satisfying experience for
                CliQQ app users.
              </p>
            </div>
          </div>
        </section>

        <section className="sections">
          <div className="screens-container">
            <div className="content">
              <img
                src={Screen1}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen2}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen3}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen4}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen5}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen6}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen7}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen8}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen9}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen10}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen11}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen12}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen13}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen14}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
              <img
                src={Screen15}
                alt="Screen"
                className="sc-img"
                loading="lazy"
              />
            </div>
          </div>
        </section>

        {/* Continuing the Journey section */}
        <section className="sections animsec">
          <div className="sc-outer-container-1 flex">
            <div
              className="cj-bg lb-img animatedcont"
              onClick={() => handleLightboxOpen(Cliqqimg8)}
            ></div>
            <div className="sc-inner-container animatedcont">
              <div className="content">
                <h5 className="d-sm-bold">Continuing the Journey</h5>
                <p className="t-md-regular de-t-xl-regular">
                  As I wrap up redesigning the CliQQ mobile app's UI/UX, I'm
                  eager to enhance user experience. Gathering feedback from
                  friends has been invaluable, guiding improvements to ensure a
                  seamless and engaging interface. Moving forward, I plan to
                  continue refining the design based on user insights, focusing
                  on usability and enhancing overall satisfaction. This project
                  marks a milestone in my journey to innovate and elevate
                  CliQQ's UI/UX, reinforcing my commitment to user-centered
                  design and continuous improvement.
                </p>
              </div>
            </div>
          </div>
        </section>
      {/* </main> */}

      {/* LightBox for thumbsnail */}
      <LightBox
        isOpen={isLightboxOpen}
        image={lightboxImage}
        onClose={handleCloseLightbox}
      />

      {/* Button to scroll up */}
      <Link
        to="/CliQQ"
        className={`stt-btn ${isHideBtn ? "hideBtn" : "showBtn"}`}
        onClick={() => {
          scroll.scrollToTop({ behavior: "smooth", passive: true });
        }}
      >
        <img src={ScrollArrow} alt="Arrow" />
      </Link>
    </>
  );
}

export default transition2(CliQQ);
