import React, { useEffect } from "react";

// Styles sheet
import "./LightBox.css";

// Assets
import Close from "../../assets/img/Close.svg";

const LightBox = React.memo(({ isOpen, image, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="lightbox-overlay" onClick={onClose}>
      <div className="lightbox-close" onClick={onClose}>
        <img src={Close} alt="Close" />
      </div>
      <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
        <img src={image} alt="lightbox" />
      </div>
    </div>
  );
});

export default LightBox;
