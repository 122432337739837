import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ReactLenis } from "lenis/react";
import React, { Suspense, lazy } from "react";
import { AnimatePresence } from "framer-motion";

// Components
import CursorAnimation from "./Components/CursorAnimation/CursorAnimation";
import Footer from "./Components/Footer/Footer";
import Loading from "./Components/Loader/Loading";
import Navbar from "./Components/Navbar/Navbar";
import NetStatus from "./Components/NetworkStatus/NetworkStatus";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import Password from "./Components/AuthPassword/Password";

// Pages
import CliQQ from "./pages/CaseStudy/CliQQ";
import HealthHub from "./pages/CaseStudy/HealthHub";
import Netto from "./pages/CaseStudy/Netto";
import ConfidentialWeb from "./pages/CaseStudy/Maxicare";
const Home = lazy(() => import("./pages/Home/Home"));
// const CliQQ = transition2(lazy(() => import("./pages/CaseStudy/CliQQ")));
// const HealthHub = transition2(
//   lazy(() => import("./pages/CaseStudy/HealthHub"))
// );
// const Netto = transition2(lazy(() => import("./pages/CaseStudy/Netto")));
// const Maxicare = transition2(lazy(() => import("./pages/CaseStudy/Maxicare")));
const NotFound = lazy(() => import("./Components/ProgressionPage/NotFound"));

function App() {
  return (
    <div className="wrapper" id="wrapper">
      <NetStatus>
        <Router>
          <ReactLenis
            root
            options={{ orientation: "both", easing: "easeOutCirc" }}
          >
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>
                <Navbar />
                <AppRoutes />
                <Footer />
              </Suspense>
            </ErrorBoundary>
          </ReactLenis>
        </Router>
      </NetStatus>
    </div>
  );
}

function AppRoutes() {
  const location = useLocation(); // Get the current location to use as key for routing

  return (
    <>
      <CursorAnimation />
      <AnimatePresence mode="wait">
        <main style={{ position: "relative" }}>
          <Routes location={location} key={location.pathname}>
            <Route path="/" index element={<Home />} />
            <Route path="/CliQQ" element={<CliQQ />} />
            <Route path="/HealthHub" element={<HealthHub />} />
            <Route path="/Netto" element={<Netto />} />
            <Route path="/ConfidentialWeb" element={<ConfidentialWeb />} />
            <Route path="/*" element={<NotFound />} />
            <Route path="/Password" element={<Password />} />
          </Routes>
        </main>
      </AnimatePresence>
    </>
  );
}

export default App;
