import React from 'react'
import '../GraphBackground/GraphBg.css';

function GraphBackground() {
  return (
    <div className='bg'></div>
  )
}

export default GraphBackground
