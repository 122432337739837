import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

const Cursor = styled.div`
  position: fixed;
  top: -1rem;
  left: -0.5rem;
  width: 16px;
  height: 16px;
  background-color: var(--clr-primary-6);
  border-radius: 50%;
  pointer-events: none;
  z-index: 1200;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--clr-base-1);
  font-size: 3px;

  @media (max-width: 1280px) {
    display: none;
  }
`;

const CustomCursor = () => {
  const cursorRef = useRef(null);
  const textRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const cursor = cursorRef.current;
    const text = textRef.current;

    const moveCursor = (e) => {
      gsap.to(cursor, {
        x: e.clientX,
        y: e.clientY,
        duration: 0.8,
        ease: "power3.out",
      });
    };

    const handleMouseEnter = (textToShow) => {
      gsap.to(cursor, {
        scale: 8,
        backgroundColor: "var(--clr-primary-6)",
      });
      if (text) {
        text.textContent = textToShow;
        text.style.display = "block";
      }
    };

    const handleMouseLeave = () => {
      gsap.to(cursor, {
        scale: 1,
        backgroundColor: "var(--clr-primary-6)",
      });
      if (text) text.style.display = "none";
    };

    // Add event listeners for cursor movement and hover effects
    document.addEventListener("mousemove", moveCursor);
    document.querySelectorAll(".vp-cards, .lb-img").forEach((link) => {
      link.addEventListener("mouseenter", () =>
        handleMouseEnter(
          link.classList.contains("vp-cards") ? "Explore" : "View"
        )
      );
      link.addEventListener("mouseleave", handleMouseLeave);
    });

    // Cleanup function to remove event listeners when component unmounts or dependencies change
    return () => {
      document.removeEventListener("mousemove", moveCursor);
      document.querySelectorAll(".vp-cards, .lb-img").forEach((link) => {
        link.removeEventListener("mouseenter", () =>
          handleMouseEnter(
            link.classList.contains("vp-cards") ? "Explore" : "View"
          )
        );
        link.removeEventListener("mouseleave", handleMouseLeave);
      });

      // Reset cursor state when cleaning up
      gsap.to(cursor, {
        scale: 1,
        backgroundColor: "var(--clr-primary-6)",
      });

      if (text) text.style.display = "none";
    };
  }, [location.pathname]); // Dependency on location.pathname ensures cleanup and re-setup on route change

  useEffect(() => {
    // Reset cursor state on route change
    const cursor = cursorRef.current;
    gsap.to(cursor, {
      scale: 1,
      backgroundColor: "var(--clr-primary-6)",
    });
  }, [location.pathname]);

  return (
    <Cursor ref={cursorRef}>
      <span ref={textRef} style={{ display: "none" }}>
        Explore
      </span>
    </Cursor>
  );
};

export default CustomCursor;
