import React, { useState, useEffect } from "react";

function NetworkStatus({ children }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const updateNetworkStatus = () => {
    setIsOnline(navigator.onLine);
    if (navigator.onLine) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    };
  }, []);

  return <>{isOnline && children}</>;
}

export default NetworkStatus;
