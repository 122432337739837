import React from "react";
import PageProgression from "../../Components/ProgressionPage/Progression";
import transition2 from "../../Components/PageTransition2/Transition2";

function HealthHub() {
  return (
    <>
      <PageProgression />
    </>
  );
}

export default transition2(HealthHub);
