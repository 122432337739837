import React from "react";

// Components
import GraphBg1 from "../../Components/GraphBackground/GraphBg1";

// Styles sheet
import "./Enp.css";

// Assets
import SlowServer from "../../assets/img/SlowServer.svg";

function PageProgression() {
  return (
    <>
      <div className="enp">
        <GraphBg1 />
        <div className="content">
          <img src={SlowServer} alt="illustration" loading="lazy" />
          <h4 className="d-md-bold de-d-lg-bold">Hmm... I Think the Server's Slow Again</h4>
          <p className="t-xl-regular">
            While you wait, why not make a coffee or take a moment to sip the
            one you have? Thanks for your patience!
          </p>
        </div>
      </div>
    </>
  );
}

export default PageProgression;
